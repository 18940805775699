:root {
  --Background: #000000;

  --Primary: #F40000;
  --PrimaryVariant: #480000;

  --Secondary: #3872FF;
  --SecondaryVariant: #07004C;

  --Third: #42DEB5;
  --ThirdVariant: #EBEF3F;

  --Neutral1: #FFFFFF;
  --Neutral2: #D1D1D5;
  --Neutral3: #C7C7CB;
  --Neutral4: #B0B0B0;
  --Neutral5: #626262;
  --Neutral6: #464646;
  --Neutral7: #2D2D2D;
  --Neutral8: #1C1C1E;
  --Neutral9: #141416;
  --Neutral10: #101010;

  --Martinique: #32324E;
  --Mandy: #E4606D;

  --toastify-toast-max-height: fit-content;
  --toastify-toast-min-height: fit-content;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--Neutral6) transparent;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--Neutral6);
    border-radius: 6px;
  }
}

/**
 * Swiper Carousel
 */
.swiper-carousel {
  --swiper-pagination-color: var(--Neutral2);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 15px;
  --swiper-pagination-bullet-width: 15px;
  --swiper-pagination-bullet-height: 15px;
  --swiper-pagination-bullet-inactive-color: var(--Neutral5);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

/**
 * Toastify Toast
 */
.Toastify__toast-body {
  padding: 8px 0;
  margin: 0;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  padding: 0 16px;
  margin-left: 16px;
  margin-top: 50px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  border: none;
  position: fixed;
  transform: none;
  right: inherit;

  @media (min-width: 600px) {
    margin-top: 30px;
  }

  &.Toastify__toast-theme--light,
  &.Toastify__toast-theme--dark {
    &.Toastify__toast--error {
      background-color: var(--PrimaryVariant);
    }

    &.Toastify__toast--info {
      background-color: var(--SecondaryVariant);
    }

    &.Toastify__toast--success {
      background-color: var(--Third);
    }

    &.Toastify__toast--warning {
      background-color: var(--ThirdVariant);
    }
  }
}
