.competition-detail-modal .carousel .thumbs-wrapper .thumbs .thumb {
	border: none !important;
}

.competition-detail-modal .carousel .thumbs-wrapper .thumbs {
	margin-top: 1rem;
	display: flex;
	align-items: center;
}

.competition-detail-modal .carousel .thumbs-wrapper {
	background-color: #1a1a1a;
	z-index: 100000;
	display: flex;
	align-items: center;
	margin: 0;
}

.competition-detail-modal .carousel .thumbs-wrapper .control-arrow {
	opacity: 1;
	background: rgba(0, 0, 0, 0.2);
	height: 100%;
	top: 0;
	margin-top: 0;
	padding: 0;
}

@media screen and (min-width: 745px) and (max-width: 768px) {
  .comp-detail-carousel {
    .badge-block {
      margin: 0px 60px;
      width: unset;
      right: 0;
    }
  }
}

@media screen and (min-width: 701px) and (max-width: 744px) {
  .comp-detail-carousel {
    .badge-block {
      margin: 0px 45px;
      width: unset;
      right: 0;
    }
  }
}

@media screen and (min-width: 676px) and (max-width: 700px) {
  .comp-detail-carousel {
    .badge-block {
      margin: 0px 25px;
      width: unset;
      right: 0;
    }
  }
}

@media screen and (min-width: 655px) and (max-width: 675px) {
  .comp-detail-carousel {
    .badge-block {
      margin: 0px 10px;
      width: unset;
      right: 0;
    }
  }
}

@media screen and (max-width: 768px) {
	.competition-detail-modal .carousel-slider .slider-wrapper .slider .slide div picture img {
		object-fit: contain;
	}

	.testimonial .carousel.carousel-slider .control-arrow,
	.all-time-winners .carousel.carousel-slider .control-arrow {
		top: 20%;
	}

	.tab-item {
		width: 100%;
		padding: 0 0.5rem;
	}
}

@media screen and (min-width: 768px) {
	.img-modal-close-btn {
		position: absolute;
		right: 10px;
	}

	.competition-detail-modal .carousel .thumbs-wrapper {
		justify-content: center;
	}

	.copyright-area a {
		font-size: 0.9rem;
	}

	.instant-winner-btn {
		font-size: 1.25rem;
	}
}

/* Mobile Layout: 320px. */
@media screen and (max-width: 767px) {
	.iubenda-cs-reject-btn {
		font-size: 14px !important;
	}
	#iubenda-cs-banner .iubenda-cs-opt-group.iubenda-cs-opt-group button.iubenda-cs-accept-btn, #iubenda-cs-banner .iubenda-cs-opt-group button.iubenda-cs-btn-primary {
		font-size: 14px !important;
	}
	#iubenda-cs-banner .iubenda-cs-customize-btn.iubenda-cs-customize-btn {
		font-size: 14px !important;
	}
	.iubenda-cs-rationale .iubenda-banner-content.iubenda-custom-content #iubenda-cs-paragraph#iubenda-cs-paragraph .iub-p {
		font-size: 14px !important;
	}
	.iubenda-cs-rationale .iubenda-banner-content.iubenda-custom-content #iubenda-cs-title#iubenda-cs-title {
		font-size: 14px !important;
	}

	.single-nav {
		/* padding: 30px 4px; */
		min-height: 100px;
		max-height: 100px;
		overflow: hidden;
		font-size: 0.9rem;
	}

	.row.navbar-row {
		justify-content: center;
		align-items: center;
	}
}

/* Wide Mobile Layout: 480px. */
.blog-container {
	font-size: 1.2rem !important;
}

.blog-container h1 {
	font-size: 2.5rem;
}

.blog-container h2 {
	font-size: 2.2rem;
}

.blog-container h3 {
	font-size: 2rem;
}

.blog-container h4 {
	font-size: 1.8rem;
}

.blog-container h5 {
	font-size: 1.6rem;
}

.blog-container h6 {
	font-size: 1.5rem;
}

@media screen and (max-width: 767px) {
	.blog-container {
		font-size: 1rem !important;
	}

	.blog-container h1 {
		font-size: 2.2rem;
	}

	.blog-container h2 {
		font-size: 2rem;
	}

	.blog-container h3 {
		font-size: 1.8rem;
	}

	.blog-container h4 {
		font-size: 1.6rem;
	}

	.blog-container h5 {
		font-size: 1.5rem;
	}

	.blog-container h6 {
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 767px) {
	.iw-drawer {
		width: 100% !important;
	}

	.week-numbers {
		padding-left: 100px !important;
	}

	.winner-podium-title {
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 430px) {

	.blog-content img {
		width: 100%;
		padding: 0;
		margin-bottom: 1rem;
	}

	.competition-detail img {
		max-height: 200px;
	}

	.carousel .portrait {
		height: 100%;
	}
}

.more-winners-close-btn {
	bottom: -80px;
}

/* Wide Mobile Layout: 480px. */
@media screen and (max-width: 480px) {
	.more-winners-close-btn {
		bottom: -55px;
	}

	.comp-detail-carousel .ribbon-top-right {
		right: 5px;
		top: -10px;
	}
}

@media screen and (max-width: 992px) {
	.comp_detail_sub_text {
		text-align: center;
	}

	.active .main-menu {
		transform: translateX(0px);
    z-index: 10;
	}
}
